/*Built for everfaery.com
	Branding colors:
	- Main Blue background: 5654aa (light) / 3c3b80 (dark)
	- Accent: 575599
	- Logo base color: daeff4
	- Highlight: cae9f5
	- Font: c7c7c7 (grey), daeff4 (light blue)
*/

@import url('https://fonts.googleapis.com/css2?family=Lato&family=Josefin+Sans&family=Poppins&display=swap');

body,
h1, h2, h3, h4, h5, h6,
span,
p,
ul,
div,
.ReactTable thead > tr > th,
.ReactTable tbody > tr > th {
	font-family: 'Lato', sans-serif;
	color: #b3b3b3;
}

body {
	background-color: #17191e;
	font-size: 18px;
	font-weight: 300;
}

.main-content {
	margin-top: 70px;
	margin-bottom:100px;
}

nav {
	margin-bottom: 50px;
}

.navbar-brand {
	/*x, y, radius, color*/
	-webkit-filter: drop-shadow(-5px 5px 4px #1b1a3c);
	filter: drop-shadow(-5px 5px 4px #1b1a3c);
}

.bullet-list {
	list-style-type: circle;
}

/* Link Colors */
a:link,
a:visited {
	text-decoration: none;
	color: #6e77cd;
}

a.btn,
a.nav-link,
footer a:link,
footer a:visited {
	text-decoration: none;
	color: #daeff4;
}

.nav-link {
	font-family: 'Poppins', sans-serif;
}

.nav-link:hover,
footer a:hover,
a:hover {
	color: #ffffff;
}

.navbar {
	background-color: #4c499b;
	text-transform: uppercase;
	font-size: 16px;
	letter-spacing: 0.5px;
}

.circle-icon {
	background-color: #5654aa;
	color: #cae9f5;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 25px;
	text-align: center;
	vertical-align: middle;
	margin: 0px 3px 0px 3px;
}

.circle-icon:hover {
	background-color: #daeff4;
	color: #5654aa;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 25px;
	text-align: center;
	vertical-align: middle;
}

footer {
	background-color: #39387a;
	font-size: 14px;
	padding: 5px;
	color: #ffffff;
}

footer ul {
	list-style-type: none;
	display: inline-block;
	margin-bottom: 0px;
	margin-right: 20px;
}

footer ul li {
	display: inline-block;
	margin: 10px;
}

footer .iconLinks {
	display: inline-block;
	padding-left: 50px;
}

footer .circle-icon,
footer .circle-icon:hover {
	width: 30px;
	height: 30px;
	line-height: 30px;
	font-size: 18px;
}


/*FAQ Accordion*/
.accordion,
.accordion-item,
.accordion-header,
.accordion-button,
.accordion-button:not(.collapsed),
.accordion-body,
.accordion-collapse {
	background-color: transparent;
	border: 0px;
}

.accordion-button:focus {
	box-shadow: none;
}

.accordion-body {
	padding-bottom: 50px;
	font-size: 18px;
}

.accordion-button {
	color: #fff;
	font-size: 20px;
}

.accordion-button:not(.collapsed) {
	color: #daeff4;
}

.accordion-button.collapsed::after{
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23daeff4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/*Front page gallery*/
.gallery-thumbnail {
	object-fit: scale-down;
	width: 100%;
	display: block;
	-webkit-box-flex: 1;
	flex-grow: 1;
}

.product-container {
padding-bottom: 50px;
}

.product-container .title{
	display: block;
	font-weight: 400;
	font-size: 20px;
}

.product-container .link {
	display: block;
}


/* Elements (buttons, icons, etc.) */
.btn {
	background-color: transparent;
	border-color: #daeff4;
	border-radius: 25px;
}

.btn:hover {
	background-color: #3c3b80;
	border-color: #daeff4;
}

.btn:hover,
.btn:hover h4,
.btn:hover p {
	color: #daeff4;
}

.bx-check {
	font-size: 1.3em;
	font-weight: 400;
	color: #03ad31;
}

.bx-x {
	font-size: 1.3em;
	font-weight: 400;
	color: #db001a;
}

/* Redelivery Page */
.purchases-table .btn {
	background-color: #5654aa;
	border: 0;
	margin: 5px;
}

.purchases-table .btn span,
.purchases-table .btn {
	color: #cae9f5;
}

.purchases-table .btn:hover {
	background-color: #daeff4;
	border: 0;
	margin: 5px;
}

	.purchases-table .btn:hover span,
	.purchases-table .btn:hover {
		color: #5654aa;
	}

.purchases-table .btn.disabled, .btn:disabled, fieldset:disabled .btn {
	background-color: #848484;
	border: 0;
	margin: 5px;
}

.profile {
	padding-left: 0;
}

.profile li {
	margin-bottom: 20px;
}

/*VIP table*/
.comparison {
	color: #cae9f5;
}
.comparison th:first-child, td:first-child {
	text-align: left;
	border: 0;
}

.comparison th {
	font-size: 20px;
}

.comparison td {
	padding: 15px;

}

.comparison thead th {
	padding: 10px;
}

	.comparison thead th:nth-child(2) {
		background-color: #3c3b80;
		border: 1px solid #848484;
	}

	.comparison thead th:nth-child(3) {
		background-color: #5654aa;
		border: 1px solid #848484;
	}
.comparison tbody tr td{
	border: 1px solid #848484;
}
